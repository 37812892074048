import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FAQ from "../../components/Index/FAQ"
import ReactNotification from 'react-notifications-component'

const TutorialPage = () => {
  return(
    <Layout>
      <ReactNotification />
      <SEO title="FAQ" path="/faq"/>
      <FAQ />
    </Layout>
  )
}

export default TutorialPage
